export const HOME_DETAILS_REQUEST = 'HOME_DETAILS_REQUEST';
export const HOME_DETAILS_SUCCESS = 'HOME_DETAILS_SUCCESS';
export const HOME_DETAILS_FAIL = 'HOME_DETAILS_FAIL';


export const CREATE_HOME_REQUEST = 'CREATE_HOME_REQUEST';
export const CREATE_HOME_SUCCESS = 'CREATE_HOME_SUCCESS';
export const CREATE_HOME_FAIL = 'CREATE_HOME_FAIL';
export const CREATE_HOME_RESET = 'CREATE_HOME_RESET';

export const DELETE_HOME_REQUEST = 'DELETE_HOME_REQUEST';
export const DELETE_HOME_SUCCESS = 'DELETE_HOME_SUCCESS';
export const DELETE_HOME_FAIL = 'DELETE_HOME_FAIL';
export const DELETE_HOME_RESET = 'DELETE_HOME_RESET';

export const CREATE_MEDIA_REQUEST = 'CREATE_MEDIA_REQUEST';
export const CREATE_MEDIA_SUCCESS = 'CREATE_MEDIA_SUCCESS';
export const CREATE_MEDIA_FAIL = 'CREATE_MEDIA_FAIL';
export const CREATE_MEDIA_RESET = 'CREATE_MEDIA_RESET';

export const ALL_MEDIA_REQUEST = 'ALL_MEDIA_REQUEST';
export const ALL_MEDIA_SUCCESS = 'ALL_MEDIA_SUCCESS';
export const ALL_MEDIA_FAIL = 'ALL_MEDIA_FAIL';

export const DELETE_MEDIA_REQUEST = 'DELETE_MEDIA_REQUEST';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAIL = 'DELETE_MEDIA_FAIL';
export const DELETE_MEDIA_RESET = 'DELETE_MEDIA_RESET';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';