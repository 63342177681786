export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAIL = 'CONTACT_US_FAIL';
export const CONTACT_US_RESET = 'CONTACT_US_RESET';


export const CONTACT_COMPANY_REQUEST = 'CONTACT_COMPANY_REQUEST';
export const CONTACT_COMPANY_SUCCESS = 'CONTACT_COMPANY_SUCCESS';
export const CONTACT_COMPANY_FAIL = 'CONTACT_COMPANY_FAIL';
export const CONTACT_COMPANY_RESET = 'CONTACT_COMPANY_RESET';


export const CONTACT_DETAILS_REQUEST = 'CONTACT_DETAILS_REQUEST';
export const CONTACT_DETAILS_SUCCESS = 'CONTACT_DETAILS_SUCCESS';
export const CONTACT_DETAILS_FAIL = 'CONTACT_DETAILS_FAIL';

export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_FAIL = 'CREATE_CONTACT_FAIL';
export const CREATE_CONTACT_RESET = 'CREATE_CONTACT_RESET';

export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL';
export const DELETE_CONTACT_RESET = 'DELETE_CONTACT_RESET';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';