export const ALL_PROJECTS_REQUEST = 'ALL_PROJECTS_REQUEST';
export const ALL_PROJECTS_SUCCESS = 'ALL_PROJECTS_SUCCESS';
export const ALL_PROJECTS_FAIL = 'ALL_PROJECTS_FAIL';

export const PROJECT_DETAILS_REQUEST = 'PROJECT_DETAILS_REQUEST';
export const PROJECT_DETAILS_SUCCESS = 'PROJECT_DETAILS_SUCCESS';
export const PROJECT_DETAILS_FAIL = 'PROJECT_DETAILS_FAIL';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';
export const DELETE_PROJECT_RESET = 'DELETE_PROJECT_RESET';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';
export const CREATE_PROJECT_RESET = 'CREATE_PROJECT_RESET';

export const ADMIN_PROJECTS_REQUEST = 'ADMIN_PROJECTS_REQUEST';
export const ADMIN_PROJECTS_SUCCESS = 'ADMIN_PROJECTS_SUCCESS';
export const ADMIN_PROJECTS_FAIL = 'ADMIN_PROJECTS_FAIL';


export const CLEAR_ERRORS = 'CLEAR_ERRORS';