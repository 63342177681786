export const ALL_MEMBERS_REQUEST = 'ALL_MEMBERS_REQUEST';
export const ALL_MEMBERS_SUCCESS = 'ALL_MEMBERS_SUCCESS';
export const ALL_MEMBERS_FAIL = 'ALL_MEMBERS_FAIL';

export const ALL_FEEDBACKS_REQUEST = 'ALL_FEEDBACKS_REQUEST';
export const ALL_FEEDBACKS_SUCCESS = 'ALL_FEEDBACKS_SUCCESS';
export const ALL_FEEDBACKS_FAIL = 'ALL_FEEDBACKS_FAIL';

export const ALL_CARDS_REQUEST = 'ALL_CARDS_REQUEST';
export const ALL_CARDS_SUCCESS = 'ALL_CARDS_SUCCESS';
export const ALL_CARDS_FAIL = 'ALL_CARDS_FAIL';

export const CREATE_MEMBER_REQUEST = 'CREATE_MEMBER_REQUEST';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAIL = 'CREATE_MEMBER_FAIL';
export const CREATE_MEMBER_RESET = 'CREATE_MEMBER_RESET';

export const DELETE_MEMBER_REQUEST = 'DELETE_MEMBER_REQUEST';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAIL = 'DELETE_MEMBER_FAIL';
export const DELETE_MEMBER_RESET = 'DELETE_MEMBER_RESET';

export const CREATE_FEEDBACK_REQUEST = 'CREATE_FEEDBACK_REQUEST';
export const CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS';
export const CREATE_FEEDBACK_FAIL = 'CREATE_FEEDBACK_FAIL';
export const CREATE_FEEDBACK_RESET = 'CREATE_FEEDBACK_RESET';

export const DELETE_FEEDBACK_REQUEST = 'DELETE_FEEDBACK_REQUEST';
export const DELETE_FEEDBACK_SUCCESS = 'DELETE_FEEDBACK_SUCCESS';
export const DELETE_FEEDBACK_FAIL = 'DELETE_FEEDBACK_FAIL';
export const DELETE_FEEDBACK_RESET = 'DELETE_FEEDBACK_RESET';

export const CREATE_CARD_REQUEST = 'CREATE_CARD_REQUEST';
export const CREATE_CARD_SUCCESS = 'CREATE_CARD_SUCCESS';
export const CREATE_CARD_FAIL = 'CREATE_CARD_FAIL';
export const CREATE_CARD_RESET = 'CREATE_CARD_RESET';

export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAIL = 'DELETE_CARD_FAIL';
export const DELETE_CARD_RESET = 'DELETE_CARD_RESET';


export const CREATE_LINK_REQUEST = 'CREATE_LINK_REQUEST';
export const CREATE_LINK_SUCCESS = 'CREATE_LINK_SUCCESS';
export const CREATE_LINK_FAIL = 'CREATE_LINK_FAIL';
export const CREATE_LINK_RESET = 'CREATE_LINK_RESET';


export const GET_LINK_REQUEST = 'GET_LINK_REQUEST';
export const GET_LINK_SUCCESS = 'GET_LINK_SUCCESS';
export const GET_LINK_FAIL = 'GET_LINK_FAIL';

export const DELETE_LINK_REQUEST = 'DELETE_LINK_REQUEST';
export const DELETE_LINK_SUCCESS = 'DELETE_LINK_SUCCESS';
export const DELETE_LINK_FAIL = 'DELETE_LINK_FAIL';
export const DELETE_LINK_RESET = 'DELETE_LINK_RESET';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';