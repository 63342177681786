export const FOOTER_DETAILS_REQUEST = 'FOOTER_DETAILS_REQUEST';
export const FOOTER_DETAILS_SUCCESS = 'FOOTER_DETAILS_SUCCESS';
export const FOOTER_DETAILS_FAIL = 'FOOTER_DETAILS_FAIL';

export const UPDATE_FOOTER_REQUEST = 'UPDATE_FOOTER_REQUEST';
export const UPDATE_FOOTER_SUCCESS = 'UPDATE_FOOTER_SUCCESS';
export const UPDATE_FOOTER_FAIL = 'UPDATE_FOOTER_FAIL';
export const UPDATE_FOOTER_RESET = 'UPDATE_FOOTER_RESET';


export const CLEAR_ERRORS = 'CLEAR_ERRORS';