export const ALL_SERVICES_REQUEST = 'ALL_SERVICES_REQUEST';
export const ALL_SERVICES_SUCCESS = 'ALL_SERVICES_SUCCESS';
export const ALL_SERVICES_FAIL = 'ALL_SERVICES_FAIL';

export const SERVICES_DETAILS_REQUEST = 'SERVICES_DETAILS_REQUEST';
export const SERVICES_DETAILS_SUCCESS = 'SERVICES_DETAILS_SUCCESS';
export const SERVICES_DETAILS_FAIL = 'SERVICES_DETAILS_FAIL';

export const CREATE_SERVICE_REQUEST = 'CREATE_SERVICE_REQUEST';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAIL = 'CREATE_SERVICE_FAIL';
export const CREATE_SERVICE_RESET = 'CREATE_SERVICE_RESET';

export const DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL';
export const DELETE_SERVICE_RESET = 'DELETE_SERVICE_RESET';


export const CLEAR_ERRORS = 'CLEAR_ERRORS';